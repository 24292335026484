import http from "./index";

const updateCampaignsStatus = async ({ campaigns, action }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.put(
    "/campaigns",
    {
      campaigns,
      action,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

const updateCampaign = async (
  campaignId,
  accountId,
  data,
  isABO = false,
  start,
  end
) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.put(
    `/campaigns/${campaignId}/${accountId}?isABO=${isABO}&start=${start}&end=${end}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

const updateMassCampaigns = async (payload) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.put(`/campaigns/bulk-update`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

// write a resetCooldown function here to reset the campaign cooldown
// its a PUT to campaigns/reset-cooldown. take the secret code -> check if it matched with secret code in env
const resetCooldown = async (secretCode) => {
  console.log(process.env.REACT_APP_SECRET_CODE, secretCode);
  if (secretCode !== process.env.REACT_APP_SECRET_CODE) {
    throw new Error("Secret code is not correct");
  }

  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.put(
    `/campaigns/reset-cooldown`,
    { logId: null },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};
export {
  updateCampaignsStatus,
  updateCampaign,
  updateMassCampaigns,
  resetCooldown,
};
